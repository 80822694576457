import React, { FC, useEffect, useState } from 'react';

interface Props {
    defaultValue: any,
    onChange: (checked: boolean) => void
}

const CheckBox : FC<Props> = (props) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(props.defaultValue);
    },[]);

    return (
        <div className="checkbox-container d-flex justify-content-start align-items-center">
            <input
                checked={checked}
                type="checkbox"
                className="checkbox-input"
                onChange={() => {setChecked(!checked); props.onChange(!checked);}}
            />
        </div>
    );
}
export default CheckBox;