import { FC, useState, useEffect, useContext } from 'react';
import I18n from '../../helpers/i18n';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import DirectusManager from '../../helpers/directusManager';
import ToolTip from '../../components/tooltip';
import LanguageIcon from '@mui/icons-material/Language';
import { UserContext } from '../../context/userContext';

interface Props {
    title?: string;
    public: boolean;
}

const Header: FC<Props> = (props) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [userName, setUserName] = useState('----');
    const userContext = useContext(UserContext);

    const doLogout = () => {
        DirectusManager.shared
            .logout()
            .then(() => {
                if (userContext != null) {
                    userContext.name = '';
                    userContext.role = '';
                    userContext.supplier_id = 0;
                    userContext.auth = false;
                }
                navigate('/login');
            })
            .catch((error) => {
                if (userContext != null) {
                    userContext.name = '';
                    userContext.role = '';
                    userContext.supplier_id = 0;
                    userContext.auth = false;
                }
                navigate('/login');
            });
    };

    const toggleLang = () => {
        if (i18n.language == 'es') {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('es');
        }
        window.location.reload();
    };

    useEffect(() => {
        if (userName !== '----') {
            return;
        }
        setLoader(true);
        DirectusManager.shared.directus.users.me
            .read()
            .then((user) => {
                setUserName(user.first_name);
                setLoader(false);
            })
            .catch((error) => {
                // if (error.response) {
                //     if (error.response.status === 401 || error.response.status === 403) {
                //         navigate('/login');
                //     }
                // }
                // setLoader(false);
            });
    }, []);

    if (props.public) {
        return (
            <div className='page-header d-flex justify-content-between align-items-center'>
                <h1 className='h3 title'>{props.title}</h1>
            </div>
        );
    } else {
        return (
            <div className='page-header d-flex justify-content-between align-items-center'>
                <h1 className='h3 title'>{props.title}</h1>
                {!loader && (
                    <div className='d-flex align-items-center'>
                        <ToolTip text={I18n.t('SWITCH_LANG_TO')} bottom>
                            <div className='lang-switch' onClick={() => toggleLang()}>
                                <div className='d-flex align-items-center'>
                                    <LanguageIcon className='lang-icon' /> {I18n.t('THIS_LANG')}
                                </div>
                            </div>
                        </ToolTip>
                        <ToolTip text={I18n.t('LOGOUT_HOVER')} bottom>
                            <div className='d-flex align-items-center user-block' onClick={() => doLogout()}>
                                <span className='name'>
                                    {I18n.t('LOGGED_IN_AS')}: {userName}
                                </span>
                            </div>
                        </ToolTip>
                    </div>
                )}
            </div>
        );
    }
};
export default Header;
