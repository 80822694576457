import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import I18n from '../../helpers/i18n';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
    title: string;
    path?: string;
    disabled?: boolean;
    fullwidth?: boolean;
    onPress?: () => void;
    //styles
    defaultStyle?: boolean;
    warningStyle?: boolean;
    cancelStyle?: boolean;
    altStyle?: boolean;
    //components
    icon?: any;
}

const Button: FC<Props> = (props) => {
    const navigate = useNavigate();

    const onPress = (e: any) => {
        e.preventDefault();

        if (props.disabled) {
            return false;
        } else if (props.path) {
            navigate(props.path);
        } else if (props.onPress) {
            props.onPress();
        }
    };

    const getStyle = () => {
        var style = 'button';
        if (props.defaultStyle) {
            style = style + ' default';
        }
        if (props.altStyle) {
            style = style + ' alt';
        }
        if (props.warningStyle) {
            style = style + ' warning';
        }
        if (props.cancelStyle) {
            style = style + ' cancel';
        }

        if (props.fullwidth) {
            style = style + ' fullwidth';
        }

        if (props.disabled) {
            style = style + ' disabled';
        }

        if (props.icon) {
            style = style + ' icon-spacing';
        }

        return style;
    };

    return (
        <a href={props.path} className={getStyle()} onClick={(event) => onPress(event)}>
            {props.icon && <span className='icon'>{props.icon}</span>}
            {props.title}
        </a>
    );
};
export default Button;
