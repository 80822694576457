import React, { FC, useEffect, useState, useContext } from 'react';
import I18n from '../../helpers/i18n';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { UserContext } from '../../context/userContext';
import Button from '../button';
import DirectusManager from '../../helpers/directusManager';
import Loader from '../../components/loader';

interface Props {}

const EmailEditor: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const [template, setTemplate] = useState('');
    const userContext = useContext(UserContext);
    const editorValue = EditorState.createWithContent(ContentState.createFromText(I18n.t('EMAIL_EXAMPLE')));
    const [editorState, setEditorState] = useState(editorValue);
    const [copySuccess, setCopySuccess] = useState('');
    const [saveSuccess, setSaveSuccess] = useState('');

    useEffect(() => {
        console.log(saveSuccess);
    }, [saveSuccess]);

    useEffect(() => {
        loadTemplate();
    }, []);

    useEffect(() => {
        if (template.length > 0) {
            let editorValue = EditorState.createWithContent(ContentState.createFromText(template));
            setEditorState(editorValue);
        }
        setLoader(false);
    }, [template]);

    const updateClipboard = () => {
        let copyUrl = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        navigator.clipboard.writeText(copyUrl).then(
            () => {
                setCopySuccess(I18n.t('HTML_SUCCESS_COPY'));
            },
            () => {
                setCopySuccess(I18n.t('HTML_FAIL_COPY'));
            }
        );
    };

    const loadTemplate = () => {
        setLoader(true);
        let supplier_id = userContext.supplier_id;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                supplier_id: supplier_id,
            }),
        };

        fetch(DirectusManager.shared.baseUrl + '/get-email-template', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response != undefined) {
                    setTemplate(response);
                }
            })
            .catch((error) => {
                //Sentry.captureException(error);
                console.log(error);
            });
    };

    const updateTemplate = () => {
        setLoader(true);
        let supplier_id = userContext.supplier_id;
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const template_value = blocks.map((block) => (!block.text.trim() && '\n') || block.text).join('\n');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                supplier_id: supplier_id,
                email_template: template_value,
            }),
        };

        fetch(DirectusManager.shared.baseUrl + '/save-email-template', requestOptions)
            .then((response) => {
                setSaveSuccess(I18n.t('HTML_SUCCESS_SAVE'));
                return response.json();
            })
            .catch((error) => {
                setSaveSuccess(I18n.t('HTML_FAIL_SAVE'));
                //Sentry.captureException(error);
                console.log(error);
            });
        setLoader(false);
    };

    return (
        <div>
            {loader ? (
                <div className='validation-loader'>
                    <Loader show={loader} />
                </div>
            ) : (
                <div>
                    <div className='editor-container'>
                        <Editor
                            editorState={editorState}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-editor'
                            onEditorStateChange={(val) => setEditorState(val)}
                        />
                    </div>
                    <div className='result-container'>
                        <h4>{I18n.t('RESULT')}</h4>
                        {saveSuccess && saveSuccess !== '' ? <div>{saveSuccess}</div> : <div></div>}
                        {copySuccess && copySuccess !== '' ? <div>{copySuccess}</div> : <div></div>}
                        <div className='page-header d-flex justify-content-end align-items-right copy-button'>
                            <Button
                                defaultStyle
                                title={I18n.t('COPY_HTML')}
                                icon={<ContentCopyIcon />}
                                onPress={() => updateClipboard()}
                            />
                            <div className='save-button d-flex'>
                                <Button
                                    defaultStyle
                                    title={I18n.t('HTML_SAVE')}
                                    icon={<SaveIcon />}
                                    onPress={() => updateTemplate()}
                                />
                            </div>
                        </div>
                        <textarea
                            className='result'
                            disabled
                            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmailEditor;
