import React from 'react';

interface UserInterface {
    name: string;
    role: string;
    supplier_id: number;
    user_id: string;
    auth: undefined | boolean;
}

const userInterfaceInitial: UserInterface = {
    name: '',
    role: '',
    supplier_id: 0,
    auth: false,
    user_id: '',
};

export const UserContext = React.createContext<UserInterface>(userInterfaceInitial);
