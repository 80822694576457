import DirectusManager from './directusManager';
import I18n from './i18n';
import moment from 'moment';

export default class RelationManager {
    static shared: RelationManager = new RelationManager();

    //Product Group Relations:
    referrals(id: number) {
        return new Promise(function (resolve, reject) {
            var checkForConnections = DirectusManager.shared.directus.items('referrals');
            checkForConnections
                .readByQuery({ limit: -1, filter: { deleted: false, id: id } })
                .then((data) => {
                    if (data.data !== null && data.data !== undefined && data.data.length > 0) {
                        var relations: any = data.data;
                        var text: any = I18n.t('REFERRAL') + ':\n';

                        for (var a = 0; a < relations.length; a++) {
                            if (a == 0) {
                                text = text + relations[a].id;
                            } else {
                                text = text + ',\n' + relations[a].id;
                            }
                        }
                        resolve([relations, text]);
                    } else {
                        resolve([[], '']);
                    }
                })
                .catch((error) => {
                    reject([]);
                });
        });
    }
}
