import React, { FC } from 'react';

interface Props {
    show: boolean;
}

const Loader: FC<Props> = (props) => {
    return props.show ? (
        <div className='btn-loader'>
            <div className='loader' />
        </div>
    ) : null;
};
export default Loader;
