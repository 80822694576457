import DirectusManager from './directusManager';

export const CreateCoupon = async (discount_amount: string) => {
    const randomString = (length: number) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            auth_token: DirectusManager.shared.directus.auth.token,
            code: randomString(6),
            discount_amount: discount_amount,
        }),
    };

    const x = await fetch(DirectusManager.shared.baseUrl + '/create-coupon', requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    return x;
};

export const ValidateReferral = async (code: string, invoice_id: string) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            code: code,
            invoice_id: invoice_id,
        }),
    };

    const x = await fetch(DirectusManager.shared.baseUrl + '/validate-referral', requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    return x;
};

export const SaveCopuonID = async (code: string, coupon_id: string, coupon_code: string) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            code: code,
            coupon_id: coupon_id,
            coupon_code: coupon_code,
        }),
    };

    const x = await fetch(DirectusManager.shared.baseUrl + '/save-coupon-id', requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    return x;
};
