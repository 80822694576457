import { useState, FC, useEffect, useRef, useContext } from 'react';
import I18n from '../../helpers/i18n';
import Helpers from '../../helpers/functions';
import DirectusManager from '../../helpers/directusManager';
import { UserContext } from '../../context/userContext';
import Wrapper from '../../components/wrapper';
import Snackbar from '../../components/snackbar';
import Table from '../../components/tables/overview-table-full';
import * as Sentry from '@sentry/react';

interface Props {}

const ProfitList: FC<Props> = (props) => {
    const Validators = Helpers.instance;
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);
    const [unpaidData, setUnpaidData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount1, setPageCount1] = useState<number>(1);
    const [currentPage1, setCurrentPage1] = useState<number>(1);
    const snackBar = useRef();
    const userContext = useContext(UserContext);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        setIsAdmin(userContext.role === '620dc560-c18e-4df1-86b2-99201c69200e');
    }, []);

    useEffect(() => {
        getData();
    }, [isAdmin]);

    const changePage = (page: number) => {
        // getData(activeFilter, activeQuery, page + 1);
        // setCurrentPage(page + 1);
    };

    const changePage1 = (page: number) => {
        // getData(activeFilter, activeQuery, page + 1);
        // setCurrentPage(page + 1);
    };

    const getSupData = async (id: any) => {
        const a = await getSupplierData(id);
        return a;
    };

    const getSupplierData = async (id: number) => {
        if (id == undefined) return;
        var user = await DirectusManager.shared.directus.items('directus_users');
        const response = await user
            .readOne(id)
            .then((user) => {
                if (user !== undefined && user !== null) {
                    return user;
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error);
                return false;
            });
        return response;
    };

    const getData = async (filters?: any, query?: string, page?: number) => {
        var filter: any = { deleted: false };
        var sort: any = ['-date_created'];
        var fields: any = [
            'id',
            'month',
            'supplier_id.user_id',
            'total_referrals',
            'total_referrals_validated',
            'profit',
            'paid',
            'date_created',
            'date_updated',
            'user_created',
        ];
        if (filters) {
            filter = filters;
            filter['deleted'] = false;
        }
        if (query) {
            filter = Validators.searchStringFilter('id', query);
            filter['deleted'] = false;
        }
        var supplier_payments = DirectusManager.shared.directus.items('supplier_payments');
        supplier_payments
            .readByQuery({ fields: fields, meta: 'filter_count', filter, sort: sort, limit: 10, page: page ?? 1 })
            .then(async (payments) => {
                if (payments !== undefined && payments.data !== undefined && payments.data !== null) {
                    const promises = payments.data.map((y: any) => getSupData(y.supplier_id.user_id));
                    const resolve = await Promise.all(promises);
                    const mapped_paid = payments.data
                        .filter((x) => {
                            if (x.paid === true && isAdmin) {
                                return true;
                            } else if (x.paid === true && x.supplier_id.user_id === userContext.user_id) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .map((y) => {
                            const name: any = resolve.find((z: any) => {
                                let n = '';
                                if (z.id === y.supplier_id.user_id) {
                                    return z;
                                }
                                return n;
                            });
                            return {
                                id: y.id,
                                paid: y.paid,
                                col0: y.id,
                                col1: y.month,
                                col2: name.first_name ? name.first_name + ' ' + name.last_name : '',
                                col3: y.total_referrals,
                                col4: y.total_referrals_validated,
                                col5: y.profit,
                            };
                        });
                    const mapped_unpaid = payments.data
                        .filter((x) => {
                            if (x.paid === false && isAdmin) {
                                return true;
                            } else if (x.paid === false && x.supplier_id.user_id === userContext.user_id) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .map((y) => {
                            const name: any = resolve.find((z: any) => {
                                let n = '';
                                if (z.id === y.supplier_id.user_id) {
                                    return z;
                                }
                                return n;
                            });
                            return {
                                id: y.id,
                                paid: y.paid,
                                col0: y.id,
                                col1: y.month,
                                col2: name.first_name ? name.first_name + ' ' + name.last_name : '',
                                col3: y.total_referrals,
                                col4: y.total_referrals_validated,
                                col5: y.profit,
                            };
                        });
                    setData(mapped_paid);
                    setUnpaidData(mapped_unpaid);
                    if (payments.meta?.filter_count !== undefined) {
                        var totalPages = Math.ceil(payments.meta?.filter_count / 20);
                        setPageCount(totalPages);
                    }
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error.message);
            });
    };

    const confirmPaid = (id: string, value: boolean) => {
        var p = DirectusManager.shared.directus.items('supplier_payments');
        p.updateOne(id, { paid: !value })
            .then((data) => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ITEM_UPDATED'));
                getData();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    return (
        <Wrapper title={I18n.t('PROFIT_LIST')} public={false}>
            <Table
                allowExport={false}
                title={I18n.t('PAYED_PROFIT_LIST_OVERVIEW_TITLE')}
                showActions={true}
                allowNew={false}
                type='payments'
                loading={loader}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('ID'),
                            accessor: 'col0',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('MONTH'),
                            accessor: 'col1',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('SUPPLIER'),
                            accessor: 'col2',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TOTAL_REFERRALS'),
                            accessor: 'col3',
                            type: 'status',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TOTAL_VALIDATED_REFERRALS'),
                            accessor: 'col4',
                            type: 'status',
                            Link: false,
                        },
                        {
                            Header: I18n.t('AMOUNT'),
                            accessor: 'col5',
                            type: 'status',
                            Link: false,
                        },
                    ],
                }}
                data={data}
                pageCount={pageCount}
                currentPage={currentPage}
                changePage={(page: number) => changePage(page)}
                // confirmDelete={(id: number) => confirmDelete(id)}
                allowPayment={isAdmin}
                confirmPaid={(id: any, value: boolean) => confirmPaid(id, value)}
            />

            <Table
                allowExport={false}
                title={I18n.t('NOT_PAYED_PROFIT_LIST_OVERVIEW_TITLE')}
                showActions={true}
                allowNew={false}
                type='payments'
                loading={loader}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('ID'),
                            accessor: 'col0',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('MONTH'),
                            accessor: 'col1',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('SUPPLIER'),
                            accessor: 'col2',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TOTAL_REFERRALS'),
                            accessor: 'col3',
                            type: 'status',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TOTAL_VALIDATED_REFERRALS'),
                            accessor: 'col4',
                            type: 'status',
                            Link: false,
                        },
                        {
                            Header: I18n.t('AMOUNT'),
                            accessor: 'col5',
                            type: 'status',
                            Link: false,
                        },
                    ],
                }}
                data={unpaidData}
                pageCount={pageCount1}
                currentPage={currentPage1}
                changePage={(page: number) => changePage1(page)}
                allowPayment={isAdmin}
                confirmPaid={(id: any, value: boolean) => confirmPaid(id, value)}
            />

            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default ProfitList;
