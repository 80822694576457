import { FC, useState, useEffect } from 'react';
import I18n from '../../../helpers/i18n';
import moment from 'moment';
import SimpleTable from '../detail-rows-single-col';
import DirectusManager from '../../../helpers/directusManager';
import Helpers from '../../../helpers/functions';
const Validators = Helpers.instance;

interface Props {
    type: string;
    title: string;
    name: string;
    errorName?: boolean;
    errorNameMessage?: string;
    setName: (x: any) => void;
    phone: string;
    errorPhone?: boolean;
    errorPhoneMessage?: string;
    setPhone: (x: any) => void;
    saveCustomer: (x: any) => void;
}

const Table: FC<Props> = (props) => {
    const getCurrentDate = () => {
        var date = moment().format('DD-MM-YYYY');
        return date;
    };

    const saveCustomer = () => {
        var referral = {};
        props.saveCustomer(referral);
    };

    return (
        <>
            <SimpleTable
                largeSize
                title={props.title}
                save={() => saveCustomer()}
                hideMandatory
                saveButtonText={I18n.t('SAVE_CUSTOMER')}
                rows={[
                    {
                        title: I18n.t('TABLE_REFERRAL_DATE_CREATED'),
                        value: getCurrentDate(),
                    },
                    {
                        title: I18n.t('TABLE_CUSTOMER_NAME'),
                        input: true,
                        value: props.name,
                        onChange: (val: any) => props.setName(val),
                        price: false,
                        mandatory: true,
                        error: props.errorName,
                        errorText: props.errorNameMessage,
                    },
                    {
                        title: I18n.t('TABLE_CUSTOMER_PHONE'),
                        value: props.phone,
                        input: true,
                        onChange: (val: any) => props.setPhone(val),
                        price: false,
                        mandatory: true,
                        error: props.errorPhone,
                        errorText: props.errorPhoneMessage,
                    }
                ]}
            />
        </>
    );
};
export default Table;
