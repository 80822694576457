import { useState, FC, useEffect } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';
import * as Sentry from '@sentry/react';
import { useParams, useNavigate } from 'react-router-dom';
import Table from '../../components/tables/customer-detail';
import DirectusManager from '../../helpers/directusManager';

const CustomerDetail: FC = (props) => {
    const navigate = useNavigate();
    const params = useParams<{ id: any }>();
    const [data, setData] = useState<any>({});

    useEffect(() => {
        reloadCustomer();
    }, []);

    const reloadCustomer = () => {
        let cus = DirectusManager.shared.directus.items('customers');
        cus.readOne(params.id, { fields: ['*.*'] })
            .then((cus) => {
                setData(cus);
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error.message);
            });
    };

    const confirmDelete = (id: number) => {
        var cus = DirectusManager.shared.directus.items('customers');
        cus.updateOne(id, { deleted: true })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const returnMain = () => {
        navigate('/customers');
    };

    return (
        <Wrapper title={I18n.t('CUSTOMER DETAIL')} public={false}>
            <Table
                data={data}
                customer={true}
                confirmDelete={(id: number) => confirmDelete(id)}
                reloadCustomer={() => reloadCustomer()}
            />
        </Wrapper>
    );
};

export default CustomerDetail;
