import { Directus } from '@directus/sdk';
export default class DirectusManager {
    static shared: DirectusManager = new DirectusManager();

    production = false;

    baseUrl = this.production ? 'https://referralgruvy.tecdam.io' : 'https://referralgruvy.tecdam.io';
    directus = new Directus(this.baseUrl, { auth: { autoRefresh: true } });

    login(credentials: any) {
        return this.directus.auth.login(credentials);
    }

    logout() {
        return this.directus.auth.logout();
    }

    isLoggedIn() {
        return this.directus.auth.token != null;
    }

    readUserMe() {
        return this.directus.users.me.read();
    }
}
