import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

const PageBehaviour: FC = (props) => {
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
};

export default PageBehaviour;
