import React, { FC, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import I18n from '../../../helpers/i18n';
import Button from '../../button';
import moment from 'moment';
import Select from '../../select';
import InputField from '../../input';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';
import SimpleTable from '../detail-rows-single-col';
import Tooltip from '../../tooltip';
import DirectusManager from '../../../helpers/directusManager';
import Alert from '../../alert';
import Helpers from '../../../helpers/functions';

const Validators = Helpers.instance;

interface Props {
    data?: any;
    type: string;
    title: string;
    discount: string;
    discountError?: boolean;
    discountErrorMessage?: string;

    // if customers
    customers?: any;
    selectedCustomer?: number;
    setSelectedCustomer?: (id: number) => void;
    // setDiscount: (val: any) => void;
    saveReferral: (data: any) => void;

    invoiceID: string;
    setInvoiceID: (x: any) => void;
    errorInvoice?: boolean;
    errorInvoiceMessage?: string;
}

const Table: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        let type = '';
        switch (props.type) {
            case 'referrals':
                type = 'referrals';
                break;
            default:
                break;
        }

        if (type === '') {
            return;
        }
        var availableOrders = DirectusManager.shared.directus.items(type);
        let sort: any = ['-date_created'];
        availableOrders
            .readByQuery({ fields: ['*.*.*.*'], limit: 10, sort: sort })
            .then((orders: any) => {
                if (orders.data !== undefined && orders.data.length > 0) {
                } else {
                }
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                console.log(error.message);
            });
    }, []);

    const getCurrentDate = () => {
        var date = moment().format('DD-MM-YYYY');
        return date;
    };

    const saveReferral = () => {
        var referral = {};
        props.saveReferral(referral);
    };

    return (
        <>
            <SimpleTable
                largeSize
                title={props.title}
                save={() => saveReferral()}
                hideMandatory
                saveButtonText={I18n.t('SAVE_REFERRAL')}
                disabledButton={props.selectedCustomer ? false : true}
                rows={[
                    {
                        title: I18n.t('TABLE_REFERRAL_DATE_CREATED'),
                        value: getCurrentDate(),
                    },
                    {
                        mandatory: true,
                        condition: props.customers && props.setSelectedCustomer ? true : false,
                        title: I18n.t('TABLE_REFERRAL_CUSTOMER'),
                        value: props.selectedCustomer,
                        select: true,
                        array: props.customers ? props.customers : [],
                        onChange: (value: any) => props.setSelectedCustomer && props.setSelectedCustomer(value),
                    },
                    {
                        title: I18n.t('TABLE_CUSTOMER_INVOICE_ID'),
                        value: props.invoiceID,
                        input: true,
                        onChange: (val: any) => props.setInvoiceID(val),
                        price: false,
                        mandatory: true,
                        error: props.errorInvoice,
                        errorText: props.errorInvoiceMessage,
                    },
                    // {
                    //     title: I18n.t('TABLE_DISCOUNT'),
                    //     value: props.discount,
                    //     onChange: (val: any) => props.setDiscount(val),
                    //     input: true,
                    //     mandatory: true,
                    //     error: props.discountError,
                    //     errorText: props.discountErrorMessage,
                    // },
                ]}
            />
        </>
    );
};
export default Table;
