import { FC, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
    data: any;
}
const Chart: FC<Props> = (props) => {
    return (
        <div className='chart-static'>
            <Doughnut
                data={props.data}
                options={{
                    responsive: true,
                    layout: {
                        padding: {
                            bottom: 20,
                            top: 10,
                        },
                    },
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Total validatation rate',
                        },
                    },
                }}
            />
        </div>
    );
};

export default Chart;
