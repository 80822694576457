import { FC } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';
import Robot from '../../images/404.png';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

const ProductOverview: FC = (props) => {
    return (
        <Wrapper public={false}>
            <section className='page'>
                <div className='fourofour'>
                    <div className='question'>
                        <ContactSupportIcon fontSize='large' />
                    </div>
                    <img src={Robot} alt='404 - not found' />
                    <h1 className='h3 mb-4 mt-4'>{I18n.t('NOTFOUND_TITLE')}</h1>
                    {I18n.t('NOTFOUND_TEXT')}
                </div>
            </section>
        </Wrapper>
    );
};

export default ProductOverview;
