import { FC } from 'react';
import I18n from '../../../helpers/i18n';
import SimpleTable from '../detail-rows-single-col';
import Helpers from '../../../helpers/functions';
const Validators = Helpers.instance;
interface Props {
    type: string;
    title: string;
    invoice_id: string;
    setInvoiceID: (data: any) => void;
    validateInvoice: (data: any) => void;
    loading?: boolean;
}

const Table: FC<Props> = (props) => {
    const validate = () => {
        var validate = {};
        props.validateInvoice(validate);
    };

    return (
        <>
            <SimpleTable
                loading={props.loading}
                midSize
                title={props.title}
                save={() => validate()}
                hideMandatory
                saveButtonText={I18n.t('VALIDATE')}
                rows={[
                    {
                        title: I18n.t('TABLE_REFERRAL_INVOICE_ID'),
                        value: props.invoice_id,
                        input: true,
                        onChange: (x: any) => props.setInvoiceID(x),
                    },
                ]}
            />
        </>
    );
};
export default Table;
