import { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';
import Snackbar from '../../components/snackbar';
import Table from '../../components/tables/customer-new';
import { useNavigate } from 'react-router-dom';
import DirectusManager from '../../helpers/directusManager';
import Helpers from '../../helpers/functions';
import useDebounce from '../../hooks/use-debounce';
import * as Sentry from '@sentry/react';
const Validators = Helpers.instance;

const NewCustomer: FC = (props) => {
    const navigate = useNavigate();
    const snackBar = useRef();

    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState(false);
    const [errorNameText, setErrorNameText] = useState('');

    const [phone, setPhone] = useState('');
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorPhoneText, setErrorPhoneText] = useState('');

    const debouncedNameTerm = useDebounce(name, 600);
    useEffect(() => {
        setErrorName(false);
        setErrorNameText('');
        if (name !== '') {
            let valField = Validators.validateName(name);
            setErrorName(valField[0]);
            setErrorNameText(valField[1]);
        }
    }, [debouncedNameTerm]);

    const debouncedPhoneTerm = useDebounce(phone, 600);
    useEffect(() => {
        setErrorPhone(false);
        setErrorPhoneText('');
        if (phone !== '') {
            let valField = Validators.validatePhone(phone);
            setErrorPhone(valField[0]);
            setErrorPhoneText(valField[1]);
        }
    }, [debouncedPhoneTerm]);

    const saveCustomer = async () => {
        if (errorName) return;
        if (name === '') {
            setErrorName(true);
            setErrorNameText('NAME IS REQUIRED');
            return;
        }

        if (errorPhone) return;
        if (phone === '') {
            setErrorPhone(true);
            setErrorPhoneText('PHONE IS REQUIRED');
            return;
        }

        let data = {
            name: name,
            phone: phone,
        };

        var referral = DirectusManager.shared.directus.items('customers');
        referral
            .createOne(data)
            .then((data: any) => {
                if (data) {
                    navigate('/referrals/new');
                }
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    return (
        <Wrapper title={I18n.t('ADD_NEW_CUSTOMER')} public={false}>
            <Table
                name={name}
                setName={setName}
                phone={phone}
                errorName={errorName}
                errorNameMessage={errorNameText}
                setPhone={setPhone}
                errorPhone={errorPhone}
                errorPhoneMessage={errorPhoneText}
                title={I18n.t('NEW_CUSTOMER')}
                type='customers'
                saveCustomer={() => saveCustomer()}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default NewCustomer;
