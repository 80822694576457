import React, { FC, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '../tooltip';
import I18n from '../../helpers/i18n';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventIcon from '@mui/icons-material/Event';
interface Props {
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    value: any;
    secure?: boolean;
    email?: boolean;
    error?: boolean;
    errorText?: string;
    label?: string;
    maxLength?: number;
    textArea?: boolean;
    disabled?: boolean;
    array?: any;
    clickable?: boolean;
    onClick?: () => void;
    resetFilter?: () => void;
    canReset?: boolean;
    onClose?: () => void;
    defaultValue?: string;
    useValue?: boolean;
    dateRange?: boolean;
    dateSingle?: boolean;
}
const Select: FC<Props> = (props) => {
    return (
        <div className='select-field'>
            <div className={props.error ? 'select-wrapper error' : 'select-wrapper'}>
                {props.clickable ? (
                    <div className='select clickable' onClick={props.onClick}>
                        <div className='label'>
                            {props.label}
                            {props.canReset && (
                                <div className='reset-filter' onClick={() => props.resetFilter && props.resetFilter()}>
                                    <Tooltip text={I18n.t('RESET_FILTER')}>
                                        <CloseIcon />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <select
                        className={
                            props.disabled ? 'select disabled' : props.value !== '' ? 'select not-empty' : 'select'
                        }
                        onChange={(event) => props.onChange && props.onChange(event?.target.value)}
                        disabled={props.disabled}
                        onBlur={props.onBlur}
                        defaultValue={props.defaultValue ? props.defaultValue : 'none'}
                        value={props.useValue ? props.value : undefined}
                    >
                        {props.label && (
                            <option key={'reset'} value={'reset'}>
                                {props.label}
                            </option>
                        )}
                        {props.array &&
                            props.array.length > 0 &&
                            props.array.map((item: any) => {
                                return (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                    </select>
                )}
                {!props.canReset && (
                    <div className='arrow-icon'>
                        {props.dateSingle ? (
                            <EventIcon className='p-right calender-icon' />
                        ) : props.dateRange ? (
                            <DateRangeIcon className='p-right calender-icon' />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </div>
                )}
            </div>
            {props.error && <span className='error-text d-block mb-3'>{props.errorText}</span>}
        </div>
    );
};
export default Select;
