import React, { FC, useEffect, useState, useRef } from 'react';
import I18n from '../../../helpers/i18n';
import GeneralInfo from './general';
import Alert from '../../alert';
import Snackbar from '../../../components/snackbar';
import { useNavigate, useLocation } from 'react-router-dom';
interface Props {
    data: any;
    referral?: boolean;
    loading?: boolean;
    confirmDelete?: (id: number) => void;
    confirmArchive?: (id: number) => void;
    confirmClose?: (id: number) => void;
    reloadReferral?: () => void;
}

const DetailTable: FC<Props> = (props) => {
    const snackBar = useRef();

    const [refID, setRefID] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showArchiveOrder, setShowArchiveOrder] = useState(false);
    const [showCloseOrder, setShowCloseOrder] = useState(false);

    const location = useLocation() as any;
    const navigate = useNavigate() as any;

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (location?.state == 'updated') {
            navigate('', { state: [] });
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
        }
    }, []);

    return !props.loading && !loader ? (
        <>
            <div className='d-flex page-margins'>
                <GeneralInfo
                    data={props.data}
                    referral={props.referral}
                    reloadReferral={() => {
                        if (props.reloadReferral !== undefined) {
                            props.reloadReferral();
                        }
                    }}
                />
            </div>
            <Alert
                visible={showDeleteModal}
                onConfirm={() => {
                    setShowDeleteModal(false);
                    props.confirmDelete && props.confirmDelete(refID);
                }}
                onCancel={() => setShowDeleteModal(false)}
                title={I18n.t('DELETE_ORDER') + ' ' + props.data?.order_number}
                text={I18n.t('DELETE_TEXT_PRODUCT')}
                confirmText={I18n.t('ALERT_CONFIRM_DELETE')}
                cancelText={I18n.t('ALERT_CANCEL_DELETE')}
                warning
            />
            <Alert
                visible={showCloseOrder}
                onConfirm={() => {
                    setShowCloseOrder(false);
                    props.confirmClose && props.confirmClose(refID);
                }}
                onCancel={() => setShowCloseOrder(false)}
                title={I18n.t('CLOSE_TITLE') + ' ' + props.data?.order_number}
                text={I18n.t('CLOSE_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_CLOSE')}
                cancelText={I18n.t('ALERT_CANCEL_CLOSE')}
                warning
            />
            <Alert
                visible={showArchiveOrder}
                onConfirm={() => {
                    setShowArchiveOrder(false);
                    props.confirmArchive && props.confirmArchive(refID);
                }}
                onCancel={() => setShowArchiveOrder(false)}
                title={I18n.t('ARCHIVE_TITLE') + ' ' + props.data?.order_number}
                text={I18n.t('ARCHIVE_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_ARCHIVE')}
                cancelText={I18n.t('ALERT_CANCEL_ARCHIVE')}
                defaultStyle
            />
            <Snackbar ref={snackBar} />
        </>
    ) : null;
};
export default DetailTable;
