import { useState, FC, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DirectusManager from '../../helpers/directusManager';
import Loader from '../../components/loader';
import { UserContext } from '../../context/userContext';
import * as Sentry from '@sentry/react';

interface Props {
    auth: undefined | boolean;
    setAuth: (state: boolean) => void;
}

const Auth: FC<Props> = (props) => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        DirectusManager.shared
            .readUserMe()
            .then((res) => {
                userCtx.name = res.first_name;
                userCtx.role = res.role;
                userCtx.supplier_id = res.supplier_id;
                userCtx.user_id = res.id;
                userCtx.auth = true;
                props.setAuth(true);
                setLoader(false);
                navigate('/');
            })
            .catch((err) => {
                Sentry.captureException(err);
                userCtx.name = '';
                userCtx.role = '';
                userCtx.supplier_id = 0;
                userCtx.user_id = '';
                userCtx.auth = false;
                props.setAuth(false);
                navigate('/login');
                setLoader(true);
            });
    }, []);

    return (
        <div className='d-flex justify-content-center align-items-center empty-tr'>
            <Loader show={loader} />
        </div>
    );
};

export default Auth;
