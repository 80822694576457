import I18n from './i18n';

export default class Validators {
    static validateDiscount(discount: string) {
        throw new Error('Method not implemented.');
    }
    static instance: Validators = new Validators();

    validateString(text: string): [boolean, string] {
        const only_whitespaces = /^\s+$/;
        if (text === '' || only_whitespaces.test(text)) {
            return [true, I18n.t('FORM_ERROR_REQUIRED')];
        }
        return [text === '' ? true : false, I18n.t('FORM_ERROR_REQUIRED')];
    }

    validateEmail(email: string) {
        var emailValid = false;

        if (email !== '') {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            emailValid = reg.test(email) === true;
        }

        return emailValid;
    }

    validateEmail1(email: string): [boolean, string] {
        var errorMessage = '';
        var errorState = false;

        if (email === '') {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_EMAIL_INVALID');
        } else if (email !== '') {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            var emailValid = reg.test(email) === true;

            errorState = emailValid ? false : true;
            errorMessage = I18n.t('FORM_ERROR_EMAIL_INVALID');
        }

        return [errorState, errorMessage];
    }

    validateName(string: string): [boolean, string] {
        var errorMessage = '';
        var errorState = false;

        if (string === '') {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_NAME_INVALID');
        } else if (string !== '') {
            const regex = /^[a-z ,.'-]+$/i;
            var stringValid = regex.test(string) === true;

            errorState = stringValid ? false : true;
            errorMessage = I18n.t('FORM_ERROR_NAME_INVALID');
        }

        return [errorState, errorMessage];
    }

    validatePhone(string: string): [boolean, string] {
        var errorMessage = '';
        var errorState = false;

        if (string === '') {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_PHONE_INVALID');
        } else if (string !== '') {
            const regex = /^\+[1-9]{1}[0-9]{3,14}$/;
            var stringValid = regex.test(string) === true;

            errorState = stringValid ? false : true;
            errorMessage = I18n.t('FORM_ERROR_PHONE_INVALID');
        }

        return [errorState, errorMessage];
    }

    validateDiscount(discount: string, maxDiscount: string): [boolean, string] {
        var errorMessage = '';
        var errorState = false;
        console.log('maxDiscount >= discount', maxDiscount >= discount);
        if (discount === '') {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_DISCOUNT_INVALID');
        } else if (discount !== '' && maxDiscount >= discount) {
            const regex = /^[1-9][0-9]?$|^100$/gm;
            var stringValid = regex.test(discount) === true;
            errorState = stringValid ? false : true;
            errorMessage = I18n.t('FORM_ERROR_DISCOUNT_INVALID');
        } else {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_DISCOUNT_MAX');
        }

        return [errorState, errorMessage];
    }

    validateInvoiceID(id: string): [boolean, string] {
        var errorMessage = '';
        var errorState = false;

        if (id === '') {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_INVOICEID_INVALID');
        } else if (id !== '') {
            const regex = /^[a-zA-Z0-9]{1,6}$/gm;
            var stringValid = regex.test(id) === true;
            errorState = stringValid ? false : true;
            errorMessage = I18n.t('FORM_ERROR_INVOICEID_INVALID');
        }

        return [errorState, errorMessage];
    }

    amountDisplay(amount: any) {
        if (amount === undefined || amount === null) {
            return '';
        }
        var renderToString = amount.toString();
        var amountDisplay: any;

        if (renderToString.length <= 3) {
            amountDisplay = amount;
        }

        if (renderToString.length > 3) {
            amountDisplay = renderToString.slice(0, -3) + '.' + renderToString.slice(-3);
        }

        if (renderToString.length > 7) {
            amountDisplay = amountDisplay.slice(0, -7) + '.' + amountDisplay.slice(-7);
        }

        return amountDisplay;
    }

    priceDisplay(amount: any) {
        const formatted = amount.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
        });
        return '€ ' + formatted;
    }

    camelize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    camelCase(str: string) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toUpperCase() : word.toUpperCase();
        });
    }

    upperCase(str: string) {
        return str.toUpperCase();
    }

    lowerCase(str: string) {
        return str.toLowerCase();
    }

    searchStringFilter(field: string, string: string) {
        var query: any = {};

        query = {
            _or: [
                {
                    [field]: {
                        _contains: string,
                    },
                },
                {
                    [field]: {
                        _contains: this.camelize(string),
                    },
                },
                {
                    [field]: {
                        _contains: this.camelCase(string),
                    },
                },
                {
                    [field]: {
                        _contains: this.upperCase(string),
                    },
                },
                {
                    [field]: {
                        _contains: this.lowerCase(string),
                    },
                },
            ],
        };

        return query;
    }

    validateCreateNewPassword(newPassword: string): [boolean, string, boolean] {
        const number_check = /\d/;
        const special_char_check = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (newPassword !== '') {
            if (newPassword.length >= 8 && number_check.test(newPassword) && special_char_check.test(newPassword)) {
                return [false, '', true];
            } else {
                return [true, I18n.t('FORM_PASSWORDS_INVALID'), false];
            }
        }

        return [false, '', false];
    }

    validateConfirmNewPassword(newPassword: string, repeatNewPassword: string): [boolean, string, boolean] {
        if (newPassword === repeatNewPassword) {
            return [false, '', repeatNewPassword !== '' ? true : false];
        }

        return [true, I18n.t('FORM_PASSWORDS_NOMATCH'), false];
    }
}
