import { FC, useEffect } from 'react';
import I18n from '../../../../helpers/i18n';
import moment from 'moment';

interface Props {
    data: any;
    customer?: boolean;
    reloadCustomer: () => void;
}

const GeneralInfo: FC<Props> = (props) => {
    const getDate = (date: any) => {
        if (date == null) {
            return '-';
        }
        var parseDate = moment(date).format('DD-MM-YYYY');
        return parseDate;
    };

    return (
        <div className='table-wrapper detail'>
            <div className='table-header filled d-flex justify-content-between align-items-center'>
                <span className='title'>{I18n.t('GENERAL_INFO')}</span>
            </div>
            <table className='table'>
                <thead></thead>
                <tbody>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_CUSTOMER_NAME')}</td>
                        <td>
                            <div className='d-flex align-items-center'>{props.data.name}</div>
                        </td>
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_CUSTOMER_PHONE')}</td>
                        <td>{props.data.phone}</td>
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_CUSTOMER_STATUS')}</td>
                        <td>{props.data.status == 'active' ? I18n.t('STATUS_ACTIVE') : I18n.t('STATUS_INACTIVE')}</td>
                    </tr>

                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_REFERRAL_DATE_CREATED')}</td>
                        <td>{getDate(props.data.date_created)}</td>
                    </tr>

                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_LAST_CHANGE')}</td>
                        <td>{getDate(props.data.date_updated)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
export default GeneralInfo;
