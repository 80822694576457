import { useState, FC, useRef, useEffect } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';
import { CreateCoupon, ValidateReferral, SaveCopuonID } from '../../helpers/apiWP';
import Snackbar from '../../components/snackbar';
import Table from '../../components/tables/validate-invoice';
import { useParams } from 'react-router-dom';
import Alert from '../../components/alert';
import Logo from '../../images/gruvy-long-purple.png';
import Loader from '../../components/loader';
import { useNavigate } from 'react-router-dom';

const ValidateInvoice: FC = (props) => {
    const navigate = useNavigate();
    const snackBar = useRef();
    const params = useParams<{ id: any }>();

    const [loader, setLoader] = useState(false);
    const [loaderValidation, setLoaderValidation] = useState(false);
    const [invoiceID, setInvoiceID] = useState('');
    const [code, setCode] = useState('');

    const [showValidatedModal, setShowValidatedModal] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
        if (isNaturalNumber(params.id) || params.id.length < 36) {
            navigate('/auth');
        }
    }, [params.id]);

    const isNaturalNumber = (n: string) => {
        n = n.toString(); // force the value incase it is not
        let n1 = Math.abs(+n),
            n2 = parseInt(n, 10);
        return !isNaN(n1) && n2 === n1 && n1.toString() === n;
    };

    const validateInvoice = async () => {
        setLoaderValidation(true);
        console.log('validateInvoice');

        if (invoiceID === '') {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ERROR_VALIDATING'));
            setLoaderValidation(false);
            return;
        }

        let res = await ValidateReferral(params.id, invoiceID);
        if (res.status === 'OK' && res.discount_amount !== undefined) {
            const createCuponResponse = await CreateCoupon(res.discount_amount);
            if (createCuponResponse !== undefined) {
                let result = await SaveCopuonID(params.id, createCuponResponse.coupon_id, createCuponResponse.code);
                if (result === 'OK') {
                    setCode(createCuponResponse.code);
                    setShowValidatedModal(true);
                    setLoader(false);
                    setLoaderValidation(false);
                }
            }
        } else if (res.status === 'OK' && res.code !== undefined) {
            setCode(res.code);
            setShowValidatedModal(true);
            setLoader(false);
            setLoaderValidation(false);
        } else if (res === 'incorrect-id') {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ERROR_VALIDATING'));
            setLoaderValidation(false);
        } else {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ERROR_VALIDATING'));
            setLoaderValidation(false);
        }
    };

    const updateClipboard = () => {
        let copyUrl = code;
        navigator.clipboard.writeText(copyUrl).then(
            () => {
                setCopySuccess(I18n.t('DISCOUNT_COPY_SUCCESS'));
            },
            () => {
                setCopySuccess(I18n.t('DISCOUNT_COPY_FAIL'));
            }
        );
    };

    return (
        <div className="validate">
            <div className="mt-3 mb-5">
                <img className="logo-validate" src={Logo} alt="logo Gruvy" />
            </div>
            <Wrapper title={I18n.t('VALIDATE')} public={true}>
                <div className="explanation">{I18n.t('VALIDATION_EXP')}</div>
                {loaderValidation ? (
                    <div className="validation-loader">
                        <Loader show={loaderValidation} />
                    </div>
                ) : (
                    <Table
                        loading={loader}
                        title={I18n.t('VALIDATE_INVOICE')}
                        type="referral"
                        setInvoiceID={(x: any) => setInvoiceID(x)}
                        invoice_id={invoiceID}
                        validateInvoice={validateInvoice}
                    />
                )}
                {code !== '' && (
                    <Alert
                        validation={true}
                        onCopy={updateClipboard}
                        onSuccessCopy={copySuccess}
                        value={code}
                        visible={showValidatedModal}
                        onConfirm={() => {
                            setShowValidatedModal(false);
                            window.location.replace('https://getgruvy.com/shop');
                        }}
                        onCancel={() => setShowValidatedModal(false)}
                        title={I18n.t('VALIDATED_TITLE')}
                        text={I18n.t('VALIDATED_TEXT')}
                        confirmText={I18n.t('ALERT_VALIDATED_NAVIGATE')}
                        defaultStyle
                    />
                )}
                <Snackbar ref={snackBar} />
            </Wrapper>
        </div>
    );
};

export default ValidateInvoice;
