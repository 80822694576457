import React, { FC, useEffect, useState } from 'react';
import I18n from '../../helpers/i18n';
import Chart from './chart';

interface Props {
    title: string;
    data: {
        totalReferrals: number;
        totalReferralsValidated: number;
        profit: number;
        rate: number;

        totalReferralsM: number;
        totalReferralsValidatedM: number;
        profitM: number;
        rateM: number;
    };
}

const Statics: FC<Props> = (props) => {
    const getProps = (type: any) => {
        if (type === 'totalReferrals') {
            if (props.data.totalReferrals == NaN || null || undefined) {
                return '0';
            } else {
                return props.data.totalReferrals;
            }
        }

        if (type === 'totalReferralsValidated') {
            if (props.data.totalReferralsValidated == NaN || null || undefined) {
                return '0';
            } else {
                return props.data.totalReferralsValidated;
            }
        }

        if (type === 'profit') {
            if (isNaN(props.data.profit)) {
                return '$0';
            } else {
                return '$' + props.data.profit.toFixed(2);
            }
        }

        if (type === 'totalReferralsM') {
            if (props.data.totalReferralsM == NaN || null || undefined) {
                return '0';
            } else {
                return props.data.totalReferralsM;
            }
        }

        if (type === 'totalReferralsValidatedM') {
            if (props.data.totalReferralsValidatedM == NaN || null || undefined) {
                return '0';
            } else {
                return props.data.totalReferralsValidatedM;
            }
        }

        if (type === 'profitM') {
            if (isNaN(props.data.profitM)) {
                return '$0';
            } else {
                return '$' + props.data.profitM.toFixed(2);
            }
        }

        return;
    };

    const chartDataTotal = {
        labels: ['Total', I18n.t('STATUS_VALIDATED')],
        datasets: [
            {
                label: 'Total',
                data: [props.data.totalReferrals, props.data.totalReferralsValidated],
                backgroundColor: ['#aa8cad', '#8d029d'],
                borderWidth: 1,
            },
        ],
    };
    const chartDataMonthly = {
        labels: ['Total', I18n.t('STATUS_VALIDATED')],
        datasets: [
            {
                label: 'Total',
                data: [props.data.totalReferralsM, props.data.totalReferralsValidatedM],
                backgroundColor: ['#aa8cad', '#8d029d'],
                borderWidth: 1,
            },
        ],
    };

    const getData = (type: string) => {
        if (type === 'chartDataTotal') {
            chartDataTotal.datasets.forEach((dataset) => {
                if (dataset.data.every((el: any) => el === 0)) {
                    dataset.backgroundColor.push('rgba(255,255,255,1)');
                    dataset.data.push(1);
                }
            });
            return chartDataTotal;
        } else if (type === 'chartDataMonthly') {
            chartDataMonthly.datasets.forEach((dataset) => {
                if (dataset.data.every((el: any) => el === 0)) {
                    dataset.backgroundColor.push('rgba(255,255,255,1)');
                    dataset.data.push(1);
                }
            });
            return chartDataMonthly;
        }
        return false;
    };

    return (
        <div className="statics">
            <h3 className="title">{props.title}</h3>
            <div className="block">
                {/* <div className="chart-child">
                    <h5 className="child-title">{I18n.t('MONTHLY_RATE')}</h5>
                    <hr />
                    <Chart data={getData('chartDataMonthly')} />
                </div> */}

                <div className="chart">
                    <h5 className="child-title">{I18n.t('TOTAL_RATE')}</h5>
                    <hr />
                    <Chart data={getData('chartDataTotal')} />
                </div>

                <div className="statistics">
                    <div className="sub-child">
                        <h5 className="child-title">{I18n.t('TOTAL_PROFIT_MONTHLY')}</h5>
                        <hr />
                        <div className="amount-text">{getProps('profitM')}</div>
                    </div>
                    <div className="sub-child">
                        <h5 className="child-title">{I18n.t('TOTAL_PROFIT')}</h5>
                        <hr />
                        <div className="amount-text">{getProps('profit')}</div>
                    </div>
                </div>
            </div>
            <div className="block">
                <div className="statistics">
                    <div className="sub-child">
                        <h5 className="child-title">{I18n.t('TOTAL_MONTHLY_REFERRALS')}</h5>
                        <hr />
                        <div className="amount-text">{getProps('totalReferrals')}</div>
                    </div>

                    <div className="sub-child">
                        <h5 className="child-title">{I18n.t('TOTAL_REFERRALS')}</h5>
                        <hr />
                        <div className="amount-text">{getProps('totalReferralsM')}</div>
                    </div>
                </div>
                <div className="statistics">
                    <div className="sub-child">
                        <h5 className="child-title">{I18n.t('TOTAL_VALIDATED_REFERRALS_MONTHLY')}</h5>
                        <hr />
                        <div className="amount-text">{getProps('totalReferralsValidatedM')}</div>
                    </div>
                    <div className="sub-child">
                        <h5 className="child-title">{I18n.t('TOTAL_VALIDATED_REFERRALS')}</h5>
                        <hr />
                        <div className="amount-text">{getProps('totalReferralsValidated')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Statics;
