import { FC, useRef } from 'react';
import Snackbar from '../../components/snackbar';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';

import EmailEditor from '../../components/wysiwyg-editor';
interface Props {}
const EmailTemplate: FC<Props> = (props) => {
    const snackBar = useRef();

    return (
        <Wrapper title={I18n.t('EMAIL_TEMPLATE')} public={false}>
            <EmailEditor />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default EmailTemplate;
